<template>
  <div class="investor-account">
    <div class="wrapper">
      <div class="title font-md">
        <b>증권 계좌 수정</b>
      </div>
      <div class="desc font-sm">
        <div>{{ $definitions.codes.obj.securitiesCompany[modalParams.project.secucoAcopno] }} {{ modalParams.project.invstrTrsacNo }}</div>
        <p>(최종 증권 계좌 유효성 확인 신청일 : {{ modalParams.project.accountDate }})</p>
      </div>
      <div class="part">
        <label :for="`${component.name}SecucoAcopno`" class="subject font-sm">
          <span>증권 계좌 입력</span>
        </label>
        <div class="form-group">
          <div class="input">
            <select class="form-control border-focus-point font-sm mb-10" :id="`${component.name}SecucoAcopno`" v-model="state.form.secucoAcopno">
              <option value="">증권사를 선택해주세요.</option>
              <option :value="code.key" :disabled="code.key === '20'" v-for="(code, idx) in $definitions.codes.arr.securitiesCompany" :key="idx">{{ code.value }}</option>
            </select>
            <Number :id="`${component.name}InvstTrsacNo`" className="font-sm form-control border-focus-point" :value.sync="state.form.invstrTrsacNo" :allowZero="true" :noComma="true" placeholder="계좌번호(숫자만 입력해주세요)" :maxlength="30"/>
          </div>
          <div class="alert alert-info mb-0">
            <b>- 증권 계좌 변동이 있다면 수정해주세요. 계좌 정보 수정은 프로젝트 진행 중일 때만 가능합니다.</b>
            <b>- 마이페이지 > 펀딩 프로젝트 > 증권형 > 자세히보기 > 증권 계좌 수정 부분에서 노출되는 화면입니다.</b>
            <b>- 반드시 아래의 증권사 중 한 곳의 증권 계좌를 입력해주세요.</b>
            <div>{{ $definitions.codes.arr.securitiesCompany.filter(c => c.key !== "20").map(c => c.value).join(", ") }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="action">
      <button :id="`${component.name}Submit`" class="btn btn-point btn-block font-sm" @click="submit()">{{ modalParams.project.invstrTrsacNo ? "수정하기" : "등록하기" }}</button>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import store from "@/scripts/store";
import Number from "@/components/Number";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";

function Component(initialize) {
  this.name = "modalInvestorAccount";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Number},
  setup() {
    const component = new Component(() => {
      state.form.secucoAcopno = modalParams.project.secucoAcopno;
      state.form.invstrTrsacNo = modalParams.project.invstrTrsacNo;
      store.commit("tuneModal", {component, size: "sm"});
    });

    const state = reactive({
      form: {
        subscribSeq: "",
        secucoAcopno: "",
        invstrTrsacNo: "",
      },
    });

    const modalParams = store.getters.modalParams(component);

    const submit = () => {
      if (!state.form.secucoAcopno) {
        document.getElementById(`${component.name}SecucoAcopno`).focus();
        return store.commit("setSwingMessage", "증권사를 선택해주세요.");
      } else if (!state.form.invstrTrsacNo) {
        document.getElementById(`${component.name}InvstTrsacNo`).focus();
        return store.commit("setSwingMessage", "계좌번호를 입력해주세요.");
      } else if (!lib.isNumeric(state.form.invstrTrsacNo)) {
        document.getElementById(`${component.name}InvstTrsacNo`).focus();
        return store.commit("setSwingMessage", "숫자만 입력해주세요.");
      }

      const args = {
        subscribeSeq: modalParams.project.subscribeSeq,
        secucoAcopno: state.form.secucoAcopno,
        invstrTrsacNo: state.form.invstrTrsacNo,
      };

      http.put("/api/participant/investor/account", args).then(() => {
        store.commit("closeModal", {
          name: component.name,
          onClose(modal) {
            store.dispatch("callback", {modal});
            store.commit("setSwingMessage", "증권 계좌를 수정했습니다.");
          }
        });
      }).catch(httpError());
    };

    return {component, state, modalParams, submit};
  }
});
</script>

<style lang="scss" scoped>
.investor-account {
  > .wrapper {
    background: #fff;
    padding: $px25;

    .title {
      margin-bottom: $px9;
    }

    .desc {
      color: #666;
    }

    .part {
      .form-group {
        margin-bottom: 0;

        input[type=text], input[type=tel], input[type=password], select {
          height: $formHeight;
          width: 100%;
        }

        .alert {
          margin-top: $px15;
          font-size: $px13;
          padding: $px15 $px10;

          > b {
            display: block;

            &:not(:first-child) {
              margin-top: $px15;
            }
          }

          > div {
            margin-top: $px2;
          }
        }
      }
    }
  }

  .action {
    button {
      padding: $px15;
      height: $px56;
      border-radius: 0;
    }
  }
}
</style>